// eslint-disable-next-line import/default
import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import exportingInit from 'highcharts/modules/exporting';
import HighchartsMoreInit from 'highcharts/highcharts-more';
import solidGaugeInit from 'highcharts/modules/solid-gauge';
import wordCloudInit from 'highcharts/modules/wordcloud';

HighchartsMoreInit(Highcharts);
solidGaugeInit(Highcharts);
wordCloudInit(Highcharts);
exportingInit(Highcharts);

export default defineNuxtPlugin((nuxtApp) => {
  // @ts-ignore
  nuxtApp.vueApp.use(HighchartsVue);
});
