import { default as culture_45elementspb8PJ3RzauMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-culture/culture-elements.vue?macro=true";
import { default as dissonanceelzUFNzeqPMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-culture/dissonance.vue?macro=true";
import { default as index3RyiTaPnybMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-culture/index.vue?macro=true";
import { default as open_45questionswN597t3GtiMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-culture/open-questions.vue?macro=true";
import { default as participationF5M69Y6V9cMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-culture/participation.vue?macro=true";
import { default as ranking_45by_45filteraH8MmKpjRrMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-culture/ranking-by-filter.vue?macro=true";
import { default as ranking_45by_45questionsCaMdPKfTNGMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-culture/ranking-by-questions.vue?macro=true";
import { default as ranking_45of_45valueslZaN4JzTtNMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-culture/ranking-of-values.vue?macro=true";
import { default as strength_45healthiness3xF4QSw2mLMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-culture/strength-healthiness.vue?macro=true";
import { default as types_45cultureCq98WvEaSrMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-culture/types-culture.vue?macro=true";
import { default as diagnosis_45culture2giVuKTARZMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-culture.vue?macro=true";
import { default as attrition_45clusters9qrPEmZSm8Meta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/attrition-clusters.vue?macro=true";
import { default as customized_45questionsFuJLoKNOb3Meta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/customized-questions.vue?macro=true";
import { default as dimensions_45engagementNtrPtSTjIjMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/dimensions-engagement.vue?macro=true";
import { default as engagement_45clustersPFhf5iLA3mMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/engagement-clusters.vue?macro=true";
import { default as enpsiwliNyec08Meta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/enps.vue?macro=true";
import { default as flowEbzDO1DQ91Meta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/flow.vue?macro=true";
import { default as indexzofQSR9oR0Meta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/index.vue?macro=true";
import { default as agentsjUFxpVBn42Meta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/influencers/agents.vue?macro=true";
import { default as expertsD551QsKqgZMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/influencers/experts.vue?macro=true";
import { default as mentorsh2yZwwfaQ1Meta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/influencers/mentors.vue?macro=true";
import { default as influencersej1LJXEW0fMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/influencers.vue?macro=true";
import { default as keys_45magicvqEGLBdh05Meta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/keys-magic.vue?macro=true";
import { default as open_45questionse75BBXIHmlMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/open-questions.vue?macro=true";
import { default as participationxls8SzpGP4Meta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/participation.vue?macro=true";
import { default as ranking_45by_45filterJ2vsoRl5xCMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/ranking-by-filter.vue?macro=true";
import { default as ranking_45by_45questionsFgd6Y2qhcqMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/ranking-by-questions.vue?macro=true";
import { default as diagnosis_45engagementYo9FGuDxIIMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement.vue?macro=true";
import { default as indexaSbUcaqdZsMeta } from "/var/www/app.winx.ai/pages/index.vue?macro=true";
import { default as indexIv5xjt9KyvMeta } from "/var/www/app.winx.ai/pages/okrs/plans-[plan]/index.vue?macro=true";
import { default as key_45result_45createLXH2XwJsV4Meta } from "/var/www/app.winx.ai/pages/okrs/plans-[plan]/objectives-[objective]/key-result-create.vue?macro=true";
import { default as updatektyNzXavcBMeta } from "/var/www/app.winx.ai/pages/okrs/plans-[plan]/objectives-[objective]/key-results-[keyResult]/update.vue?macro=true";
import { default as viewPd84qOt8gtMeta } from "/var/www/app.winx.ai/pages/okrs/plans-[plan]/objectives-[objective]/key-results-[keyResult]/view.vue?macro=true";
import { default as updateofjgjY6yaYMeta } from "/var/www/app.winx.ai/pages/okrs/plans-[plan]/objectives-[objective]/update.vue?macro=true";
import { default as objectives_45creategjz6UQplu3Meta } from "/var/www/app.winx.ai/pages/okrs/plans-[plan]/objectives-create.vue?macro=true";
import { default as plans_45createqH6HLn9Uo6Meta } from "/var/www/app.winx.ai/pages/okrs/plans-create.vue?macro=true";
import { default as okrsjD9yTnReogMeta } from "/var/www/app.winx.ai/pages/okrs.vue?macro=true";
import { default as loginz86OtDOe53Meta } from "/var/www/app.winx.ai/node_modules/winx-base/pages/login.vue?macro=true";
export default [
  {
    path: diagnosis_45culture2giVuKTARZMeta?.path ?? "/:campaign?/diagnosis-culture",
    children: [
  {
    name: culture_45elementspb8PJ3RzauMeta?.name ?? "campaign-diagnosis-culture-culture-elements",
    path: culture_45elementspb8PJ3RzauMeta?.path ?? "culture-elements",
    meta: culture_45elementspb8PJ3RzauMeta || {},
    alias: culture_45elementspb8PJ3RzauMeta?.alias || [],
    redirect: culture_45elementspb8PJ3RzauMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-culture/culture-elements.vue").then(m => m.default || m)
  },
  {
    name: dissonanceelzUFNzeqPMeta?.name ?? "campaign-diagnosis-culture-dissonance",
    path: dissonanceelzUFNzeqPMeta?.path ?? "dissonance",
    meta: dissonanceelzUFNzeqPMeta || {},
    alias: dissonanceelzUFNzeqPMeta?.alias || [],
    redirect: dissonanceelzUFNzeqPMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-culture/dissonance.vue").then(m => m.default || m)
  },
  {
    name: index3RyiTaPnybMeta?.name ?? "campaign-diagnosis-culture",
    path: index3RyiTaPnybMeta?.path ?? "",
    meta: index3RyiTaPnybMeta || {},
    alias: index3RyiTaPnybMeta?.alias || [],
    redirect: index3RyiTaPnybMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-culture/index.vue").then(m => m.default || m)
  },
  {
    name: open_45questionswN597t3GtiMeta?.name ?? "campaign-diagnosis-culture-open-questions",
    path: open_45questionswN597t3GtiMeta?.path ?? "open-questions",
    meta: open_45questionswN597t3GtiMeta || {},
    alias: open_45questionswN597t3GtiMeta?.alias || [],
    redirect: open_45questionswN597t3GtiMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-culture/open-questions.vue").then(m => m.default || m)
  },
  {
    name: participationF5M69Y6V9cMeta?.name ?? "campaign-diagnosis-culture-participation",
    path: participationF5M69Y6V9cMeta?.path ?? "participation",
    meta: participationF5M69Y6V9cMeta || {},
    alias: participationF5M69Y6V9cMeta?.alias || [],
    redirect: participationF5M69Y6V9cMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-culture/participation.vue").then(m => m.default || m)
  },
  {
    name: ranking_45by_45filteraH8MmKpjRrMeta?.name ?? "campaign-diagnosis-culture-ranking-by-filter",
    path: ranking_45by_45filteraH8MmKpjRrMeta?.path ?? "ranking-by-filter",
    meta: ranking_45by_45filteraH8MmKpjRrMeta || {},
    alias: ranking_45by_45filteraH8MmKpjRrMeta?.alias || [],
    redirect: ranking_45by_45filteraH8MmKpjRrMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-culture/ranking-by-filter.vue").then(m => m.default || m)
  },
  {
    name: ranking_45by_45questionsCaMdPKfTNGMeta?.name ?? "campaign-diagnosis-culture-ranking-by-questions",
    path: ranking_45by_45questionsCaMdPKfTNGMeta?.path ?? "ranking-by-questions",
    meta: ranking_45by_45questionsCaMdPKfTNGMeta || {},
    alias: ranking_45by_45questionsCaMdPKfTNGMeta?.alias || [],
    redirect: ranking_45by_45questionsCaMdPKfTNGMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-culture/ranking-by-questions.vue").then(m => m.default || m)
  },
  {
    name: ranking_45of_45valueslZaN4JzTtNMeta?.name ?? "campaign-diagnosis-culture-ranking-of-values",
    path: ranking_45of_45valueslZaN4JzTtNMeta?.path ?? "ranking-of-values",
    meta: ranking_45of_45valueslZaN4JzTtNMeta || {},
    alias: ranking_45of_45valueslZaN4JzTtNMeta?.alias || [],
    redirect: ranking_45of_45valueslZaN4JzTtNMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-culture/ranking-of-values.vue").then(m => m.default || m)
  },
  {
    name: strength_45healthiness3xF4QSw2mLMeta?.name ?? "campaign-diagnosis-culture-strength-healthiness",
    path: strength_45healthiness3xF4QSw2mLMeta?.path ?? "strength-healthiness",
    meta: strength_45healthiness3xF4QSw2mLMeta || {},
    alias: strength_45healthiness3xF4QSw2mLMeta?.alias || [],
    redirect: strength_45healthiness3xF4QSw2mLMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-culture/strength-healthiness.vue").then(m => m.default || m)
  },
  {
    name: types_45cultureCq98WvEaSrMeta?.name ?? "campaign-diagnosis-culture-types-culture",
    path: types_45cultureCq98WvEaSrMeta?.path ?? "types-culture",
    meta: types_45cultureCq98WvEaSrMeta || {},
    alias: types_45cultureCq98WvEaSrMeta?.alias || [],
    redirect: types_45cultureCq98WvEaSrMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-culture/types-culture.vue").then(m => m.default || m)
  }
],
    name: diagnosis_45culture2giVuKTARZMeta?.name ?? undefined,
    meta: diagnosis_45culture2giVuKTARZMeta || {},
    alias: diagnosis_45culture2giVuKTARZMeta?.alias || [],
    redirect: diagnosis_45culture2giVuKTARZMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-culture.vue").then(m => m.default || m)
  },
  {
    path: diagnosis_45engagementYo9FGuDxIIMeta?.path ?? "/:campaign?/diagnosis-engagement",
    children: [
  {
    name: attrition_45clusters9qrPEmZSm8Meta?.name ?? "campaign-diagnosis-engagement-attrition-clusters",
    path: attrition_45clusters9qrPEmZSm8Meta?.path ?? "attrition-clusters",
    meta: attrition_45clusters9qrPEmZSm8Meta || {},
    alias: attrition_45clusters9qrPEmZSm8Meta?.alias || [],
    redirect: attrition_45clusters9qrPEmZSm8Meta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/attrition-clusters.vue").then(m => m.default || m)
  },
  {
    name: customized_45questionsFuJLoKNOb3Meta?.name ?? "campaign-diagnosis-engagement-customized-questions",
    path: customized_45questionsFuJLoKNOb3Meta?.path ?? "customized-questions",
    meta: customized_45questionsFuJLoKNOb3Meta || {},
    alias: customized_45questionsFuJLoKNOb3Meta?.alias || [],
    redirect: customized_45questionsFuJLoKNOb3Meta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/customized-questions.vue").then(m => m.default || m)
  },
  {
    name: dimensions_45engagementNtrPtSTjIjMeta?.name ?? "campaign-diagnosis-engagement-dimensions-engagement",
    path: dimensions_45engagementNtrPtSTjIjMeta?.path ?? "dimensions-engagement",
    meta: dimensions_45engagementNtrPtSTjIjMeta || {},
    alias: dimensions_45engagementNtrPtSTjIjMeta?.alias || [],
    redirect: dimensions_45engagementNtrPtSTjIjMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/dimensions-engagement.vue").then(m => m.default || m)
  },
  {
    name: engagement_45clustersPFhf5iLA3mMeta?.name ?? "campaign-diagnosis-engagement-engagement-clusters",
    path: engagement_45clustersPFhf5iLA3mMeta?.path ?? "engagement-clusters",
    meta: engagement_45clustersPFhf5iLA3mMeta || {},
    alias: engagement_45clustersPFhf5iLA3mMeta?.alias || [],
    redirect: engagement_45clustersPFhf5iLA3mMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/engagement-clusters.vue").then(m => m.default || m)
  },
  {
    name: enpsiwliNyec08Meta?.name ?? "campaign-diagnosis-engagement-enps",
    path: enpsiwliNyec08Meta?.path ?? "enps",
    meta: enpsiwliNyec08Meta || {},
    alias: enpsiwliNyec08Meta?.alias || [],
    redirect: enpsiwliNyec08Meta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/enps.vue").then(m => m.default || m)
  },
  {
    name: flowEbzDO1DQ91Meta?.name ?? "campaign-diagnosis-engagement-flow",
    path: flowEbzDO1DQ91Meta?.path ?? "flow",
    meta: flowEbzDO1DQ91Meta || {},
    alias: flowEbzDO1DQ91Meta?.alias || [],
    redirect: flowEbzDO1DQ91Meta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/flow.vue").then(m => m.default || m)
  },
  {
    name: indexzofQSR9oR0Meta?.name ?? "campaign-diagnosis-engagement",
    path: indexzofQSR9oR0Meta?.path ?? "",
    meta: indexzofQSR9oR0Meta || {},
    alias: indexzofQSR9oR0Meta?.alias || [],
    redirect: indexzofQSR9oR0Meta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/index.vue").then(m => m.default || m)
  },
  {
    name: influencersej1LJXEW0fMeta?.name ?? "campaign-diagnosis-engagement-influencers",
    path: influencersej1LJXEW0fMeta?.path ?? "influencers",
    children: [
  {
    name: agentsjUFxpVBn42Meta?.name ?? "campaign-diagnosis-engagement-influencers-agents",
    path: agentsjUFxpVBn42Meta?.path ?? "agents",
    meta: agentsjUFxpVBn42Meta || {},
    alias: agentsjUFxpVBn42Meta?.alias || [],
    redirect: agentsjUFxpVBn42Meta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/influencers/agents.vue").then(m => m.default || m)
  },
  {
    name: expertsD551QsKqgZMeta?.name ?? "campaign-diagnosis-engagement-influencers-experts",
    path: expertsD551QsKqgZMeta?.path ?? "experts",
    meta: expertsD551QsKqgZMeta || {},
    alias: expertsD551QsKqgZMeta?.alias || [],
    redirect: expertsD551QsKqgZMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/influencers/experts.vue").then(m => m.default || m)
  },
  {
    name: mentorsh2yZwwfaQ1Meta?.name ?? "campaign-diagnosis-engagement-influencers-mentors",
    path: mentorsh2yZwwfaQ1Meta?.path ?? "mentors",
    meta: mentorsh2yZwwfaQ1Meta || {},
    alias: mentorsh2yZwwfaQ1Meta?.alias || [],
    redirect: mentorsh2yZwwfaQ1Meta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/influencers/mentors.vue").then(m => m.default || m)
  }
],
    meta: influencersej1LJXEW0fMeta || {},
    alias: influencersej1LJXEW0fMeta?.alias || [],
    redirect: influencersej1LJXEW0fMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/influencers.vue").then(m => m.default || m)
  },
  {
    name: keys_45magicvqEGLBdh05Meta?.name ?? "campaign-diagnosis-engagement-keys-magic",
    path: keys_45magicvqEGLBdh05Meta?.path ?? "keys-magic",
    meta: keys_45magicvqEGLBdh05Meta || {},
    alias: keys_45magicvqEGLBdh05Meta?.alias || [],
    redirect: keys_45magicvqEGLBdh05Meta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/keys-magic.vue").then(m => m.default || m)
  },
  {
    name: open_45questionse75BBXIHmlMeta?.name ?? "campaign-diagnosis-engagement-open-questions",
    path: open_45questionse75BBXIHmlMeta?.path ?? "open-questions",
    meta: open_45questionse75BBXIHmlMeta || {},
    alias: open_45questionse75BBXIHmlMeta?.alias || [],
    redirect: open_45questionse75BBXIHmlMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/open-questions.vue").then(m => m.default || m)
  },
  {
    name: participationxls8SzpGP4Meta?.name ?? "campaign-diagnosis-engagement-participation",
    path: participationxls8SzpGP4Meta?.path ?? "participation",
    meta: participationxls8SzpGP4Meta || {},
    alias: participationxls8SzpGP4Meta?.alias || [],
    redirect: participationxls8SzpGP4Meta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/participation.vue").then(m => m.default || m)
  },
  {
    name: ranking_45by_45filterJ2vsoRl5xCMeta?.name ?? "campaign-diagnosis-engagement-ranking-by-filter",
    path: ranking_45by_45filterJ2vsoRl5xCMeta?.path ?? "ranking-by-filter",
    meta: ranking_45by_45filterJ2vsoRl5xCMeta || {},
    alias: ranking_45by_45filterJ2vsoRl5xCMeta?.alias || [],
    redirect: ranking_45by_45filterJ2vsoRl5xCMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/ranking-by-filter.vue").then(m => m.default || m)
  },
  {
    name: ranking_45by_45questionsFgd6Y2qhcqMeta?.name ?? "campaign-diagnosis-engagement-ranking-by-questions",
    path: ranking_45by_45questionsFgd6Y2qhcqMeta?.path ?? "ranking-by-questions",
    meta: ranking_45by_45questionsFgd6Y2qhcqMeta || {},
    alias: ranking_45by_45questionsFgd6Y2qhcqMeta?.alias || [],
    redirect: ranking_45by_45questionsFgd6Y2qhcqMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement/ranking-by-questions.vue").then(m => m.default || m)
  }
],
    name: diagnosis_45engagementYo9FGuDxIIMeta?.name ?? undefined,
    meta: diagnosis_45engagementYo9FGuDxIIMeta || {},
    alias: diagnosis_45engagementYo9FGuDxIIMeta?.alias || [],
    redirect: diagnosis_45engagementYo9FGuDxIIMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/diagnosis-engagement.vue").then(m => m.default || m)
  },
  {
    name: indexaSbUcaqdZsMeta?.name ?? "index",
    path: indexaSbUcaqdZsMeta?.path ?? "/",
    meta: indexaSbUcaqdZsMeta || {},
    alias: indexaSbUcaqdZsMeta?.alias || [],
    redirect: indexaSbUcaqdZsMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/index.vue").then(m => m.default || m)
  },
  {
    name: okrsjD9yTnReogMeta?.name ?? "okrs",
    path: okrsjD9yTnReogMeta?.path ?? "/okrs",
    children: [
  {
    name: indexIv5xjt9KyvMeta?.name ?? "okrs-plans-plan",
    path: indexIv5xjt9KyvMeta?.path ?? "plans-:plan()",
    meta: indexIv5xjt9KyvMeta || {},
    alias: indexIv5xjt9KyvMeta?.alias || [],
    redirect: indexIv5xjt9KyvMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/okrs/plans-[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: key_45result_45createLXH2XwJsV4Meta?.name ?? "okrs-plans-plan-objectives-objective-key-result-create",
    path: key_45result_45createLXH2XwJsV4Meta?.path ?? "plans-:plan()/objectives-:objective()/key-result-create",
    meta: key_45result_45createLXH2XwJsV4Meta || {},
    alias: key_45result_45createLXH2XwJsV4Meta?.alias || [],
    redirect: key_45result_45createLXH2XwJsV4Meta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/okrs/plans-[plan]/objectives-[objective]/key-result-create.vue").then(m => m.default || m)
  },
  {
    name: updatektyNzXavcBMeta?.name ?? "okrs-plans-plan-objectives-objective-key-results-keyResult-update",
    path: updatektyNzXavcBMeta?.path ?? "plans-:plan()/objectives-:objective()/key-results-:keyResult()/update",
    meta: updatektyNzXavcBMeta || {},
    alias: updatektyNzXavcBMeta?.alias || [],
    redirect: updatektyNzXavcBMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/okrs/plans-[plan]/objectives-[objective]/key-results-[keyResult]/update.vue").then(m => m.default || m)
  },
  {
    name: viewPd84qOt8gtMeta?.name ?? "okrs-plans-plan-objectives-objective-key-results-keyResult-view",
    path: viewPd84qOt8gtMeta?.path ?? "plans-:plan()/objectives-:objective()/key-results-:keyResult()/view",
    meta: viewPd84qOt8gtMeta || {},
    alias: viewPd84qOt8gtMeta?.alias || [],
    redirect: viewPd84qOt8gtMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/okrs/plans-[plan]/objectives-[objective]/key-results-[keyResult]/view.vue").then(m => m.default || m)
  },
  {
    name: updateofjgjY6yaYMeta?.name ?? "okrs-plans-plan-objectives-objective-update",
    path: updateofjgjY6yaYMeta?.path ?? "plans-:plan()/objectives-:objective()/update",
    meta: updateofjgjY6yaYMeta || {},
    alias: updateofjgjY6yaYMeta?.alias || [],
    redirect: updateofjgjY6yaYMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/okrs/plans-[plan]/objectives-[objective]/update.vue").then(m => m.default || m)
  },
  {
    name: objectives_45creategjz6UQplu3Meta?.name ?? "okrs-plans-plan-objectives-create",
    path: objectives_45creategjz6UQplu3Meta?.path ?? "plans-:plan()/objectives-create",
    meta: objectives_45creategjz6UQplu3Meta || {},
    alias: objectives_45creategjz6UQplu3Meta?.alias || [],
    redirect: objectives_45creategjz6UQplu3Meta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/okrs/plans-[plan]/objectives-create.vue").then(m => m.default || m)
  },
  {
    name: plans_45createqH6HLn9Uo6Meta?.name ?? "okrs-plans-create",
    path: plans_45createqH6HLn9Uo6Meta?.path ?? "plans-create",
    meta: plans_45createqH6HLn9Uo6Meta || {},
    alias: plans_45createqH6HLn9Uo6Meta?.alias || [],
    redirect: plans_45createqH6HLn9Uo6Meta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/okrs/plans-create.vue").then(m => m.default || m)
  }
],
    meta: okrsjD9yTnReogMeta || {},
    alias: okrsjD9yTnReogMeta?.alias || [],
    redirect: okrsjD9yTnReogMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/okrs.vue").then(m => m.default || m)
  },
  {
    name: loginz86OtDOe53Meta?.name ?? "login",
    path: loginz86OtDOe53Meta?.path ?? "/login",
    meta: loginz86OtDOe53Meta || {},
    alias: loginz86OtDOe53Meta?.alias || [],
    redirect: loginz86OtDOe53Meta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/node_modules/winx-base/pages/login.vue").then(m => m.default || m)
  }
]