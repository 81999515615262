import locale_node_modules_node_modules_winx_base_locales_pt_BR from "../node_modules/winx-base/locales/pt-BR.json" assert { type: "json" };
import locale_locales_locales_pt_BR from "../locales/pt-BR.json" assert { type: "json" };
import locale_node_modules_node_modules_winx_base_locales_es_MX from "../node_modules/winx-base/locales/es-MX.json" assert { type: "json" };
import locale_locales_locales_es_MX from "../locales/es-MX.json" assert { type: "json" };
import locale_node_modules_node_modules_winx_base_locales_en_US from "../node_modules/winx-base/locales/en-US.json" assert { type: "json" };
import locale_locales_locales_en_US from "../locales/en-US.json" assert { type: "json" };
export const localeCodes = ["pt_BR","es","en"]

export const localeMessages = {
  "pt_BR": [{ key: "../node_modules/winx-base/locales/pt-BR.json", load: () => Promise.resolve(locale_node_modules_node_modules_winx_base_locales_pt_BR) },{ key: "../locales/pt-BR.json", load: () => Promise.resolve(locale_locales_locales_pt_BR) }],
  "es": [{ key: "../node_modules/winx-base/locales/es-MX.json", load: () => Promise.resolve(locale_node_modules_node_modules_winx_base_locales_es_MX) },{ key: "../locales/es-MX.json", load: () => Promise.resolve(locale_locales_locales_es_MX) }],
  "en": [{ key: "../node_modules/winx-base/locales/en-US.json", load: () => Promise.resolve(locale_node_modules_node_modules_winx_base_locales_en_US) },{ key: "../locales/en-US.json", load: () => Promise.resolve(locale_locales_locales_en_US) }],
}

export const resolveNuxtI18nOptions = async (context) => {
  const nuxtI18nOptions = Object({})
  nuxtI18nOptions.experimental = Object({"jsTsFormatResource":false})
  nuxtI18nOptions.bundle = Object({"compositionOnly":true,"runtimeOnly":false,"fullInstall":true,"dropMessageCompiler":false})
  nuxtI18nOptions.compilation = Object({"jit":true,"strictMessage":true,"escapeHtml":false})
  nuxtI18nOptions.customBlocks = Object({"defaultSFCLang":"json","globalSFCScope":false})
 const vueI18nConfigLoader = async (loader) => {
            const config = await loader().then(r => r.default || r)
            return typeof config === 'object'
              ? config
              : typeof config === 'function'
                ? await config()
                : {}
          }
  nuxtI18nOptions.vueI18n = Object({})
  nuxtI18nOptions.vueI18n.messages ??= {}
  const deepCopy = (src, des, predicate) => {
            for (const key in src) {
              if (typeof src[key] === 'object') {
                if (!typeof des[key] === 'object') des[key] = {}
                deepCopy(src[key], des[key], predicate)
              } else {
                if (predicate) {
                  if (predicate(src[key], des[key])) {
                    des[key] = src[key]
                  }
                } else {
                  des[key] = src[key]
                }
              }
            }
          }
          const mergeMessages = async (messages, loader) => {
            const layerConfig = await vueI18nConfigLoader(loader)
            const vueI18n = layerConfig || {}
            const layerMessages = vueI18n.messages || {}
            for (const [locale, message] of Object.entries(layerMessages)) {
              messages[locale] ??= {}
              deepCopy(message, messages[locale])
            }
          }
  nuxtI18nOptions.locales = [Object({"code":"pt_BR","name":"Português","iso":"pt-BR","files":["node_modules/winx-base/locales/pt-BR.json","locales/pt-BR.json"],"hashes":["c05dacc8","cc4b1e57"],"types":["static","static"]}),Object({"code":"es","name":"Espanhol","iso":"es-MX","files":["node_modules/winx-base/locales/es-MX.json","locales/es-MX.json"],"hashes":["42522942","0968852b"],"types":["static","static"]}),Object({"code":"en","name":"Inglês","iso":"en-US","files":["node_modules/winx-base/locales/en-US.json","locales/en-US.json"],"hashes":["7c25024f","1ee4fc31"],"types":["static","static"]})]
  nuxtI18nOptions.defaultLocale = "pt_BR"
  nuxtI18nOptions.defaultDirection = "ltr"
  nuxtI18nOptions.routesNameSeparator = "___"
  nuxtI18nOptions.trailingSlash = false
  nuxtI18nOptions.defaultLocaleRouteNameSuffix = "default"
  nuxtI18nOptions.strategy = "no_prefix"
  nuxtI18nOptions.lazy = false
  nuxtI18nOptions.langDir = "./locales"
  nuxtI18nOptions.rootRedirect = null
  nuxtI18nOptions.detectBrowserLanguage = Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true})
  nuxtI18nOptions.differentDomains = false
  nuxtI18nOptions.baseUrl = ""
  nuxtI18nOptions.dynamicRouteParams = false
  nuxtI18nOptions.customRoutes = "page"
  nuxtI18nOptions.pages = Object({})
  nuxtI18nOptions.skipSettingLocaleOnNavigate = false
  nuxtI18nOptions.types = "composition"
  nuxtI18nOptions.debug = false
  nuxtI18nOptions.parallelPlugin = false
  nuxtI18nOptions.i18nModules = []
  return nuxtI18nOptions
}

export const nuxtI18nOptionsDefault = Object({experimental: Object({"jsTsFormatResource":false}),bundle: Object({"compositionOnly":true,"runtimeOnly":false,"fullInstall":true,"dropMessageCompiler":false}),compilation: Object({"jit":true,"strictMessage":true,"escapeHtml":false}),customBlocks: Object({"defaultSFCLang":"json","globalSFCScope":false}),vueI18n: "",locales: [],defaultLocale: "",defaultDirection: "ltr",routesNameSeparator: "___",trailingSlash: false,defaultLocaleRouteNameSuffix: "default",strategy: "prefix_except_default",lazy: false,langDir: null,rootRedirect: null,detectBrowserLanguage: Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true}),differentDomains: false,baseUrl: "",dynamicRouteParams: false,customRoutes: "page",pages: Object({}),skipSettingLocaleOnNavigate: false,types: "composition",debug: false,parallelPlugin: false})

export const nuxtI18nInternalOptions = Object({__normalizedLocales: [Object({"code":"pt_BR","name":"Português","iso":"pt-BR","files":["node_modules/winx-base/locales/pt-BR.json","locales/pt-BR.json"],"hashes":["c05dacc8","cc4b1e57"],"types":["static","static"]}),Object({"code":"es","name":"Espanhol","iso":"es-MX","files":["node_modules/winx-base/locales/es-MX.json","locales/es-MX.json"],"hashes":["42522942","0968852b"],"types":["static","static"]}),Object({"code":"en","name":"Inglês","iso":"en-US","files":["node_modules/winx-base/locales/en-US.json","locales/en-US.json"],"hashes":["7c25024f","1ee4fc31"],"types":["static","static"]})]})
export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const isSSG = false
export const parallelPlugin = false
